$main-margin: 1rem;
$strong-font-family: "Arial Black";
$main-font: 16px "Arial", sans-serif;
$body-margin: 8px;
$theme-color: darkgrey;

html {
  background: $theme-color;
  width: 100vw;
  overflow-x: hidden;
}

body {
  margin: $body-margin;
  width: calc(100vw - #{$body-margin * 2});
  overflow-x: hidden;
}

.layout-root {
  //margin: $main-margin;
  font: $main-font;
}

.header-svg {
  height: 50px;
  width: 100%;
}

.product-grid {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  margin-top: 1.5em;
  min-height: 100vh;
}

.product-item {
  position: relative;
  height: fit-content;

  img{
    max-width: calc(100vw - #{$main-margin * 2});

  }

  a {
    display: block;
    cursor: pointer;
  }

  &:hover {
    img {
      filter: brightness(0) invert(1);
    }

    .product-title {
      display: flex;
    }
  }

  .product-title {
    z-index: 500;
    color: blue;
    text-decoration: underline;
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    align-items: center;
    text-align: center;
    justify-content: center;
    user-select: none;
    pointer-events: none;


    .title-shell {
      background: white;
    }
  }
}

.product-image-grid {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  margin-top: 15px;
}

.image-grid-item {
  display: flex;
  width: fit-content;
  margin: 0;
  flex-direction: column;
  text-align: center;
  &.multi{
    margin-right: 15px;
    margin-bottom: 15px;
  }
}

.image-wrapper {


  &.horizontal {
    width: 50vw;
  }



  img{
    width: 400px;
    max-width: calc(100vw - #{$body-margin * 2});

  }
}

.product-description {
  p {
    margin: 0;

    span {
      font: $main-font !important;
    }
  }
}

.order-widget {
  animation-name: fade-in;
  animation-duration: .6s;
  animation-fill-mode: forwards;
}

@keyframes fade-in {
  from {
    opacity: 0
  }
  to {
    opacity: 1;
  }
}

.loading{
  pointer-events: none;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  span{
    background: $theme-color;
  }
}

.error{
  top: 0;
  left: 0;
  position: fixed;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
